import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { CartContext } from '../context/CartContext';
import './Navbar.css'; // Ensure to create and import the CSS file
//import logo from '../assets/logo.png'; // Adjust the path to your logo file

const Navbar = () => {
  const { cartItems } = useContext(CartContext);

  return (
    <nav className="navbar">
      {/* Logo */}
      <Link className="logo-link" to="/">
        {/* <img src={logo} alt="Logo" className="navbar-logo" /> */}
      </Link>

      {/* Navigation Links */}
      <Link className="nav-link" to="/">Home</Link>
      <Link className="nav-link" to="/cart">
        <FontAwesomeIcon icon={faShoppingCart} />
        {cartItems.length > 0 && <span className="cart-count">{cartItems.length}</span>}
      </Link>
    </nav>
  );
};

export default Navbar;
