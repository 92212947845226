import React from 'react';
import ProductList from '../components/ProductList'

const FieldTrips = () => {
  return (
    <div>
      <h1 className='display-5'>All Upcoming Field trips</h1>
      <ProductList />
    </div>
  );
};

export default FieldTrips;
